<template>
    <div class="content-main">
        <div class="filter-wrap">
            <!-- 筛选栏 -->
            <expand-filter
                :formData="formData"
                @clickBtn="clickBtn"
                @changeBtnFormType="changeBtnFormType"
                marginBottom="0px"
            />
            <!-- 操作栏 -->
            <div slot="pageOperation">
                <el-button
                    type="primary"
                    v-has-permi="['parentBase:save']"
                    @click="handleAdd"
                >
                    添加
                </el-button>
                <el-dropdown trigger="click" style="margin-left: 10px;">
                    <!-- 批量操作 -->
                    <el-button type="info" v-has-permi="['parentBase:batch']">
                        批量操作
                        <i
                            class="el-icon-arrow-down el-icon--right"
                        />
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item  @click.native="bulkOperation(1)">
                            <el-button
                                type="primary"
                                plain
                                size="mini"
                                style="width: 100%;"
                            >
                                启用
                            </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="bulkOperation(2)">
                            <el-button
                                type="danger"
                                plain
                                size="mini"
                                style="width: 100%;"
                            >
                                禁用
                            </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="bulkOperation(3)">
                            <el-button
                                v-has-permi="['parentBase:delete']"
                                type="warning"
                                plain
                                size="mini"
                                style="width: 100%;"
                            >
                                删除
                            </el-button>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button
                    type="enquiry"
                    @click="importDialogData.dialogVisible = true"
                    v-has-permi="['parentBase:import']"
                    style="margin-left: 10px"
                >
                    导入
                </el-button>
                <el-button
                    type="enquiry"
                    @click="exportData"
                    v-has-permi="['parentBase:export']"
                >
                    导出
                </el-button>
            </div>
        </div>
        <!-- 表格 -->
        <table-data
            v-has-permi="['parentBase:list']"
            ref="table"
            v-loading="loadingTable"
            @switchHandler="switchHandler"
            :config="tableConfig"
            :tableData="tableData"
            @handleSelectionChange="handleSelectionChange"
        >
            <template v-slot:operation="slotData">
                <el-button
                    v-has-permi="['parentBase:edit']"
                    @click="tableClick('edit', slotData.data)"
                >编辑</el-button>
                <el-button
                    v-has-permi="['parentBase:resetPassword']"
                    @click="tableClick('reset', slotData.data)"
                >重置密码</el-button>
                <el-button
                    v-has-permi="['parentBase:delete']"
                    @click="tableClick('delete', slotData.data)"
                >删除</el-button>
            </template>
        </table-data>
        <!-- 分页 -->
        <pagination
            :total="pagingData.total"
            :page.sync="pagingData.pageNum"
            :limit.sync="pagingData.pageRow"
            @pagination="getList()"
        />
        <!-- 添加家长弹窗 -->
        <dialog-wrapper
            :dialogObj="parentDialogData"
            @handleClose="parentDialogClose"
        >
            <el-form
                :model="parentFormData"
                label-width="95px"
                :rules="parentFormRules"
                ref="parentFormRef"
                class="common-form"
            >
                <el-form-item label="家长姓名" prop="name">
                    <el-input
                        v-model="parentFormData.name"
                        clearable
                        :maxlength="10"
                        show-word-limit
                        placeholder="请输入家长姓名"
                        class="input-width-medium"
                    />
                </el-form-item>
                <el-form-item label="绑定学生" :required="true">
                    <el-radio v-model="bindingType" label="1">按唯一号绑定</el-radio>
                    <el-radio v-model="bindingType" label="2">按学生姓名绑定</el-radio>
                </el-form-item>
                <el-form-item v-show="bindingType === '2'" label="选择班级" :required="true">
                    <el-cascader
                        v-model="classTemporary"
                        :options="classList"
                        :props="props"
                        clearable
                        filterable
                        placeholder="请选择班级"
                        :key="cascaderKey"
                        @change="getStudentList"
                        class="input-width-medium"
                        style="width: 100%;"
                    />
                </el-form-item>
                <el-form-item v-show="bindingType === '2'" label="选择学生" :required="true">
                    <el-select
                        v-model="parentFormData.student"
                        @change="studentNoChange('id')"
                        clearable
                        filterable
                        placeholder="请选择学生"
                        style="width: 380px;margin-right: 10px"
                    >
                        <el-option
                            v-for="v in studentList"
                            :value="v.id"
                            :label="v.studentName"
                            :key="v.id"
                        />
                    </el-select>
                    <el-button
                        type="primary"
                        @click="studentCheck(parentFormData.student, 'id')"
                    >
                        验证
                    </el-button>
                </el-form-item>
                <el-form-item v-show="bindingType === '1'" label="唯一号" :required="true">
                    <el-input
                        v-model="parentFormData.studentNo"
                        @input="studentNoChange('no')"
                        placeholder="请输入唯一号"
                        style="width: 380px;margin-right: 10px"
                    />

                    <el-button
                        type="primary"
                        @click="studentCheck(parentFormData.studentNo, 'no')"
                    >
                        验证
                    </el-button>
                </el-form-item>
                <el-form-item label="验证信息" v-if="parentFormStudent.state">
                    <span style="margin-right: 20px;">姓名：{{ parentFormStudent.name }}</span>
                    <span style="margin-right: 20px;">班级：{{ parentFormStudent.class }}</span>
                    <span>唯一号：{{ parentFormStudent.studentNo }}</span>
                </el-form-item>
                <el-form-item label="手机号" prop="user">
                    <el-input
                        v-model="parentFormData.user"
                        :disabled="parentFormData.newPhone !== undefined"
                        clearable
                        placeholder="请输入手机号"
                        class="input-width-medium"
                    />
                </el-form-item>
                <el-form-item label="新手机号" prop="newPhone" v-show="parentFormData.newPhone !== undefined">
                    <el-input
                        v-model="parentFormData.newPhone"
                        clearable
                        placeholder="请输入新手机号"
                        class="input-width-medium"
                    />
                </el-form-item>
                <el-form-item label="启用状态">
                    <el-switch
                        v-model="parentFormData.state"
                        active-value="1"
                        inactive-value="0"
                    />
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input
                        v-model="parentFormData.remarks"
                        :rows="3"
                        type="textarea"
                        :maxlength="100"
                        show-word-limit
                        clearable
                        class="input-width-medium"
                    />
                </el-form-item>
            </el-form>
            <div style="text-align: right">
                <el-button
                    @click="parentDialogClose"
                >
                    取消
                </el-button>
                <el-button
                    type="primary"
                    @click="saveParentInfo"
                >
                    确定
                </el-button>
            </div>
        </dialog-wrapper>
        <!-- 导入弹窗 -->
        <dialog-wrapper :dialogObj="importDialogData" @handleClose="importDialogClose">
            <import ref="importRef" :active="importActive" :import-form="importForm"
                    :show-download="showDownload" :upload-excel-header="uploadExcelHeader"
                    :system-excel-header="systemExcelHeader" :key-map="keyMap"
                    :success-data="successData" @download="downloadImportTemplate"
                    @uploadChange="importUploadChange" @uploadRemove="importUploadRemove" @next="handleImportNext"
                    @before="handleImportBefore" @close="importDialogClose" @importSelChange="importSelChange"
                    @save="handleImportSave" />
        </dialog-wrapper>
    </div>
</template>

<script>
import { Pagination, DialogWrapper } from "common-local"
import TableData from "./Sub/TableData"
import { downloadFile } from "@/libs/utils.js"
import ExpandFilter from "./Sub/ExpandFilter.vue"
import Import from "./SummaryParent/ParentImport/index.vue"
import { SummaryParentModel } from "@/models/SummaryParent"
import XLSX2 from "xlsx"
import {formatTreeData} from "@/libs/utils";
import onResize from "@/mixins/onResize";

export default {
    name: "SummaryParent",
    mixins: [onResize],
    components: {
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
        Import
    },
    data() {
        return {
            userPhone: '',
            schoolData: [],
            tableListSel: [],
            studentList: [],
            classList: [],
            bindingType: "1",
            classTemporary: "",
            cascaderKey: true,
            props: {
                children: "child",
                value: "id",
                label: "name",
                emitPath: false
            },
            form: {
                parentPhone: "",
                name: "",
                classIdList: []
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                data: [
                    { type: "input", label: "", value: "", placeholder: "家长姓名/学生姓名", key: "name" },
                    { type: "input", label: "", value: "", placeholder: "手机号", key: "parentPhone" },
                    {
                        placeholder: "班级",
                        type: "cascader",
                        key: "classIdList",
                        value: "",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: true,
                            value: "id",
                            emitPath: false
                        },
                        clearable: false,
                        filter(data) {
                            return data.organType !== "1";
                        },
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                    }
                ]
            },
            //表格加载状态
            loadingTable: false,
            // 表格配置
            tableConfig: {
                thead: [
                    {
                        label: "家长姓名",
                        prop: "parentName",
                        align: 'center',
                    },
                    {
                        label: "手机号",
                        prop: "parentPhone",
                        align: 'center',
                    },
                    {
                        label: "学生姓名",
                        prop: "stuName",
                        align: 'center',
                    },
                    {
                        label: "学生唯一号",
                        prop: "onlyCode",
                        align: 'center',
                    },
                    {
                        label: "学号",
                        prop: "studyNo",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "启用状态",
                        prop: "isDisable",
                        type: "switch",
                        align: 'center',
                    },
                    {
                        label: "备注",
                        prop: "remark",
                        align: 'center',
                        className: 'text-spacing-reduction',
                    },
                    {
                        label: "操作",
                        align: 'center',
                        className: 'text-spacing-reduction',
                        type: "slot",
                        slotName: 'operation'
                    }
                ],
                check: true,
                height: '',
            },
            // 表格数据
            tableData: [],
            // 分页数据
            pagingData: {
                total: 0,
                pageNum: 1,
                pageRow: 20
            },
            // 添加家长弹窗数据
            parentDialogData: {
                title: "添加/编辑",
                dialogVisible: false,
                width: "650px",
            },
            // 添加家长表单数据
            parentFormData: {
                school: "",
                name: "",
                product: "cloud_campus",
                student: "",
                studentNo: "",
                user: "",
                state: "1",
                remarks: "",
            },
            parentFormStudent: {
                state: false,
                name: "",
                class: "",
                studentNo: ""
            },
            parentFormRules: {
                school: [{ required: true, message: "请选择学校名称", trigger: "change" }],
                name: [{ required: true, message: "请输入家长姓名", trigger: "blur" }],
                user: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { pattern: /^1\d{10}$/, message: "请输入正确格式手机号", trigger: "blur" }
                ],
                newPhone: [{ pattern: /^1\d{10}$/, message: "请输入正确格式手机号", trigger: "blur" }],
                remarks: [{ max: 100, message: "备注内容长度不可超过 100 字符", trigger: "blur" }],
            },
            // 导入家长弹窗数据
            importDialogData: {
                title: "导入",
                dialogVisible: false,
                width: "740px",
            },
            // 导入步骤
            importActive: 0,
            // 导入数据
            importForm: {
                file: "",
                errType: "1"
            },
            showDownload: true,
            // 上传表头数据
            uploadExcelHeader: [],
            // 系统标准字段名称
            systemExcelHeader: [
                { label: "*手机号", required: true },
                { label: "*家长姓名", required: true },
                { label: "*学生唯一号", required: true },
                { label: "*学生姓名", required: true },
            ],
            keyMap: {},
            successData: 0,
            className: ''
        }
    },
    created() {
        this.getList(true)
        this.getSchoolList()
    },
    watch: {
        async bindingType(newValue) {
            this.parentFormStudent = this.$options.data.call().parentFormStudent
            if (newValue === "1") {
                this.cascaderKey = !this.cascaderKey
                this.parentFormData.student = ""
                this.className = ''
            } else {
                this.parentFormData.studentNo = ""
                await this.getClassList()
            }
        },
        cascaderKey() {
            this.classTemporary = ""
        }
    },
    methods: {
        // 获取家长列表
        getList(pageInit) {
            if (this.form.parentPhone !== "" && this.form.parentPhone.length !== 11) {
                this.$message.warning("请输入 11 位手机号码，进行查询！")
                return
            }
            this.loadingTable = true
            if (typeof pageInit !== "undefined") {
                this.pagingData.pageNum = 1
            }
            this._fet("/school/schoolParentInfo/list", {
                ...this.form,
                pageNum: this.pagingData.pageNum,
                pageRow: this.pagingData.pageRow
            }).then(res => {
                if (res.data.code === "200") {
                    this.pagingData.total = res.data.data.totalCount
                    res.data.data.list.forEach(e => {
                        e.isDisable = e.isDisable === "1" ? "0" : "1"
                    })
                    this.tableData = res.data.data.list
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false
            })
        },
        // 获取学校列表
        getSchoolList() {
            this._fet("/school/schoolOrgan/listAuthByCondition", {
                schoolId: this.$store.state.schoolId,
                jobScope: [3],
            }).then((res) => {
                if (res.data.code === '200') {
                    const index = this.formData.data.findIndex((i) => i.type === 'cascader')
                    this.formData.data[index].list =
                        formatTreeData(res.data.data.list, "id", "parentOrg")
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        // 递归班级架构数据
        getTreeData(data, key) {
            for (let i = 0; i < data.length; i++) {
                if (!data[i][key] || data[i][key].length === 0) {
                    data[i][key] = undefined
                } else {
                    this.getTreeData(data[i][key], key)
                }
            }
            return data
        },
        // 操作栏按钮点击
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary":
                    console.log('查询条件', this.formData.data)
                    this.formData.data.forEach(e => {
                        if (e.key === 'classIdList') {
                            this.form[e.key] = e.value ? e.value :[]
                        } else {
                            this.form[e.key] = e.value
                        }
                    })
                    this.getList(true)
                    break
            }
        },
        handleAdd() {
            this.parentDialogData.dialogVisible = true
            this.parentDialogData.title = '添加'
            this.className = ''
        },
        // 批量操作
        bulkOperation(data) {
            if (this.tableListSel.length !== 0) {
                let idList = this.tableListSel.map(e => e.id)
                if (data === 3) {
                    this.$confirm("您确定要批量删除所选家长吗？", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        this._fet("/school/schoolParentInfo/delBatch", {
                            parentInfoIds: idList
                        }).then(res => {
                            if (res.data.code === "200") {
                                this.getList(true)
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg)
                            }
                        })
                    }).catch(() => {
                    })
                } else if (data === 2 || data === 1) {
                    this.$confirm("您确定要批量修改所选家长状态吗？", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        this._fet("/school/schoolParentInfo/updateDisable", {
                            parentInfoIds: idList,
                            updateIsDisable: data === 2 ? "1" : "0"
                        }).then(res => {
                            if (res.data.code === "200") {
                                this.getList(true)
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg)
                            }
                        })
                    }).catch(() => {
                    })
                }
            } else {
                this.$message.warning("未选择内容")
            }

        },
        // 学生唯一号或学生变化
        studentNoChange(type) {
            if (type === "id") {
                this.parentFormData.studentNo = ""
            } else {
                this.parentFormData.student = ""
            }
            this.parentFormStudent = this.$options.data.call().parentFormStudent
        },
        // 学生验证回调
        studentCheck(value, type) {
            if (type === "id") {
                if (this.classTemporary) {
                    if (value) {
                        let studentObj = this.studentList.find(e => {
                            return e.id === value
                        })
                        this.parentFormStudent.studentNo = studentObj.onlyCode
                        this.parentFormStudent.class = studentObj.organName
                        this.parentFormStudent.name = studentObj.studentName
                        this.parentFormStudent.state = true
                    } else {
                        this.$message.error("请选择学生后，再进行验证！")
                    }
                } else {
                    this.$message.error("请选择班级和学生后，再进行验证！")
                }
            } else {
                if (value) {
                    this._fet("/school/schoolStudent/getStudentInfo", {
                        schoolId: this.$store.state.schoolId,
                        onlyCode: value
                    }).then(async (res) => {
                        if (res.data.code === "200") {
                            this.parentFormStudent.studentNo = res.data.data.onlyCode
                            this.parentFormStudent.class = res.data.data.className
                            this.parentFormStudent.name = res.data.data.stuName
                            this.parentFormStudent.state = true
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg)
                        }
                    })
                } else {
                    this.$message.error("请输入唯一号后，再进行验证！")
                }
            }
        },
        // 获取班级列表
        getClassList() {
            this.classTemporary = ''
            let summaryParentModel = new SummaryParentModel()
            summaryParentModel.getOrganTreeBySchoolId({
                schoolId: this.$store.state.schoolId
            }).then((res) => {
                this.classRecursion(res.data.list)
                this.classList = res.data.list
                if (this.className) {
                    const gradeName = this.classList.find(i => i.name == this.className.split('/')[0])
                    const className = gradeName.child.find(i => i.name == this.className.split('/')[1])
                    this.classTemporary = className.id
                }
            })
        },
        classRecursion(data) {
            data.forEach(item => {
                if (item.organType === "4") {
                    item.child = undefined
                } else {
                    this.classRecursion(item.child)
                }
            })
        },
        // 获取学生列表
        async getStudentList() {
            this.parentFormData.student = ""
            this.parentFormData.studentNo = ""
            this.studentList = []
            this.parentFormStudent = this.$options.data.call().parentFormStudent
            let summaryParentModel = new SummaryParentModel()
            await summaryParentModel.getStuListByOrganId({
                schoolId: this.$store.state.schoolId,
                organId: this.classTemporary
            }).then((res) => {
                this.studentList = res.data.list
            })
        },
        // 添加家长
        saveParentInfo() {
            this.$refs.parentFormRef.validate((valid) => {
                if (valid) {
                    if (this.parentFormStudent.studentNo || this.parentFormStudent.name) {
                        this._fet("/school/schoolParentInfo/saveParentInfo", {
                            id: this.parentFormData.id,
                            newPhone: this.parentFormData.newPhone,
                            // schoolId: this.$store.state.schoolId,//学校ID
                            parentName: this.parentFormData.name,//家长姓名
                            phone: this.parentFormData.user,//账号，手机号
                            onlyCode: this.parentFormStudent.studentNo,//学生唯一号
                            stuName: this.parentFormStudent.name,//学生姓名
                            isDisable: this.parentFormData.state === "1" ? "0" : "1",//是否禁用0否1是
                            // productId: this.parentFormData.product,//产品ID，不传默认cloud_campus
                            remark: this.parentFormData.remarks,
                            bindType: this.bindingType// 绑定类型
                        }).then(res => {
                            if (res.data.code === "200") {
                                this.$message.success("添加成功")
                                this.getList(true)
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg)
                            }
                        })
                        this.parentDialogClose()
                    } else {
                        this.$message.error("请选择学生或输入唯一号，并进行验证")
                    }
                } else {
                    return false
                }
            })
        },
        handleSelectionChange(data) {
            this.tableListSel = data
        },
        // 添加家长弹窗关闭
        parentDialogClose() {
            this.cascaderKey = !this.cascaderKey
            this.bindingType = "1"
            this.parentFormData = this.$options.data.call().parentFormData
            this.parentFormStudent = this.$options.data.call().parentFormStudent
            this.parentDialogData.dialogVisible = false
            this.$refs.parentFormRef.resetFields()
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn()
            } else if (res.data.code === "400") {
                this.$message.error("请求异常，请稍后再试！")
            } else {
                this.$message.error(res.data.msg)
            }
        },
        // 导入家长弹窗关闭
        importDialogClose() {
            this.getList(true)
            this.importDialogData.dialogVisible = false
            this.showDownload = true
            this.importActive = 0
            this.importForm.file = ''
            this.$refs.importRef.$refs.importRef.$refs.upload.clearFiles()
            this.$refs.importRef.$refs.importRef.$data.fileName = ''
        },
        // 表格按钮操作
        async tableClick(typeMold, itemData) {
            if (typeMold === "delete") {
                this.$confirm("您确定要删除当前家长吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this._fet("/school/schoolParentInfo/delBatch", {
                        parentInfoIds: [itemData.id]
                    }).then(res => {
                        if (res.data.code === "200") {
                            this.getList(true)
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg)
                        }
                    })
                }).catch(() => {
                })
            } else if (typeMold === "edit") {
                this.parentDialogData.title = '编辑'
                this.parentFormData.school = itemData.schoolId
                if (itemData.bindType) {
                    this.bindingType = itemData.bindType
                }
                if (itemData.bindType== 2) {
                    this.className = itemData.className
                } else {
                    this.className = ''
                }
                await this.getUserPhone(itemData)
                this.$set(this, "parentFormData", {
                    id: itemData.id,
                    school: itemData.schoolId,
                    name: itemData.parentName,
                    product: itemData.productId,
                    student: itemData.bindType == 2 ? itemData.stuName : '',
                    studentNo: itemData.bindType != 2 ? itemData.onlyCode : '',
                    user: this.userPhone,
                    state: itemData.isDisable,
                    remarks: itemData.remark,
                    newPhone: ""
                })
                this.$set(this, "parentFormStudent", {
                    state: true,
                    name: itemData.stuName,
                    class: itemData.className,
                    studentNo: itemData.onlyCode
                })
                this.parentDialogData.dialogVisible = true
            } else if (typeMold === "reset") {
                this._fet("/school/schoolParentInfo/resetPassword", {
                    parentId: itemData.parentId
                }).then(res => {
                    if (res.data.code === "200") {
                        this.$message.success("重置密码成功，已重置为" + res.data.data)
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        /**
         * @Description: 获取手机号
         * @DoWhat: 获取明文手机号
         * @UseScenarios:
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-14 18:55:34
         */
         async getUserPhone (row) {
            await this._fet(`/operate/OperateParentInfo/getPhone`,{
                parentId:row.parentId
            }).then(res => {
                if (res.data.code === "200") {
                    this.userPhone = res.data.data;
                    // console.log(this.userPhone,'this.userPhone')
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
         },
        // 状态切换
        switchHandler(data) {
            this._fet("/operate/OperateParentInfo/updateDisable", {
                parentInfoIds: [data.id],
                updateIsDisable: data.isDisable === "1" ? "0" : "1"
            }).then(res => {
                if (res.data.code === "200") {
                    this.$message.success("启用状态修改成功！")
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        // 导出数据
        exportData() {
            this.formData.data.forEach(e => {
                if (e.key === 'classIdList') {
                    this.form[e.key] = e.value ? e.value :[]
                } else {
                    this.form[e.key] = e.value
                }
            })
            const loading = this.$loading({
                lock: true,
                text: "文件导出中...",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.7)"
            })
            downloadFile({ url: "/school/schoolParentInfo/export", form: this.form }, () => {
                loading.close()
            })
        },
        // 导入相关方法
        handleImportSave() {
            // 系统必填标准字段
            const requiredField = this.systemExcelHeader.filter(i => i.required === true)
            // keyMap 选中的对应关系
            let find = requiredField.find((item) => this.keyMap[item.label] === '')
            if (find) {
                return this.$message.warning('缺少必须导入字段名称')
            }
            const summaryParentModel = new SummaryParentModel()
            // 根据选中的字段对应关系，生成新的excel文件
            const file = this.getNewData()
            console.log('文件', file)
            let formData = new FormData()
            formData.append('file', file)
            formData.append('errType', this.importForm.errType)
            // let schoolId = this.formData.data.find(e => {
            //     return e.key === "schoolId"
            // })
            // let schoolObj = this.schoolData.find(e => {
            //     return e.id === schoolId
            // })
            // formData.append('schoolId', schoolObj.id)
            // formData.append('schoolName', schoolObj.schoolName)
            // this._fet("/school/schoolParentInfo/importParentInfo", {
            //     file: this.getNewData(),
            //     errType: this.importForm.errType
            // }).then((res) => {
            //     if (res.data.code === "200") {
            //         console.log('导入数据', res)
            //     } else {
            //         this.$message.error("请求处理异常，请稍后再试")
            //     }
            // })
            summaryParentModel.importParentInfo(formData).then((res) => {
                if (res.data.code === '200') {
                    this.importActive++
                    this.successData = res.data.data.rightCount
                } else if (res.data.code === '400') {
                    this.$message.error('请求异常，请稍后再试！')
                } else {
                    this.downloadErrorList(res.data)
                }
            })
        },
        importSelChange(data) {
            this.uploadExcelHeader.forEach((item) => {
                let find = Object.keys(this.keyMap).find(k => this.keyMap[k] === item.label)
                item.disabled = !!find
            })
        },
        handleImportBefore() {
            this.importActive--
            this.showDownload = false
        },
        handleImportNext() {
            if (this.importForm.file === '') {
                return this.$message.warning("上传填好的家长信息表")
            }
            this.importActive++
            if (this.importActive == 1) {
                this.getKeyMap()
            }
        },
        importUploadRemove() {
            this.importForm.file = ''
        },
        async importUploadChange(file) {
            this.importForm.file = file.raw
            let dataBinary = await this.readFile(file.raw)
            let workBook = XLSX2.read(dataBinary, {
                type: 'binary',
                cellDates: true
            })
            let workSheet = workBook.Sheets[workBook.SheetNames[0]]
            this.tableJsonData = XLSX2.utils.sheet_to_json(workSheet)
            this.uploadExcelHeader = []
            let header = []
            const range = XLSX2.utils.decode_range(workSheet['!ref'])
            for (let c = range.s.c; c <= range.e.c; c++) {
                header.push(XLSX2.utils.encode_col(c) + '1')
            }
            header.forEach((item) => {
                this.uploadExcelHeader.push({
                    label: workSheet[item].v,
                    disabled: false
                })
            })
        },
        readFile(file) {
            return new Promise(resolve => {
                let reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onload = ev => {
                    resolve(ev.target.result)
                }
            })
        },
        getNewData() {
            let newData = []
            // 上传原excel文件数据   tableJsonData
            // 上传原excel表头数据   uploadExcelHeader
            this.tableJsonData.forEach((item) => {
                let obj = {}
                this.uploadExcelHeader.forEach((itm) => {
                    const find = Object.keys(this.keyMap).find(i => this.keyMap[i] === itm.label)
                    if (find) {
                        obj[find] = item[this.keyMap[find]]
                    } else {
                        obj[itm.label] = item[itm.label]
                    }
                })
                newData.push(obj)
            })
            const ws = XLSX2.utils.json_to_sheet(newData)
            const wb = XLSX2.utils.book_new()
            XLSX2.utils.book_append_sheet(wb, ws, "学生信息导入模板.xlsx");
            let arrayBuffer = XLSX2.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
            });
            return new File([arrayBuffer], '学生信息导入模板.xlsx')
        },
        getKeyMap() {
            this.keyMap = {}
            this.systemExcelHeader.forEach((item) => {
                let header = this.uploadExcelHeader.find(e => e.label == item.label)
                this.keyMap[item.label] = header?.label ?? ''
            })
        },
        downloadErrorList(data) {
            this.$confirm(data.msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                downloadFile({
                    url: '/school/schoolParentInfo/errorExportOfParentInfo',
                    method: 'post',
                    fileName: '导入错误信息表.xlsx',
                    form: {
                        paramData: data.info.paramData
                    }
                }, () => {
                }, () => {
                    this.$message.error('请求处理异常，请稍后再试')
                })
            }).catch(() => {
            })
        },
        // 导入下载模板
        downloadImportTemplate() {
            this._get("/school/schoolParentInfo/template").then((res) => {
                if (res.data.code === "200") {
                    console.log('res.data.data', res.data.data)
                    downloadFile({
                        url: res.data.data,
                        method: "get"
                    }, () => {
                    }, () => {
                        this.$message.error("请求处理异常，请稍后再试")
                    })
                } else {
                    this.$message.error("请求处理异常，请稍后再试")
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}

.el-dropdown-menu__item {
    margin-bottom: 10px;

    &:hover {
        background-color: #fff;
    }
}
</style>
